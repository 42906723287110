import React, {Component} from "react";
import HorizontalLinearStepper from "../components/stepper";
import FormControl from "@material-ui/core/FormControl";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {Link} from "react-router-dom";
import api from "../api/index";
import {CircularProgress, Tooltip} from "@material-ui/core";
import ExitIcon from "@material-ui/icons/ExitToApp";

export default class Seller extends Component {

    constructor(props) {
        super(props);
        this.state = {
            usuraSelected: -1,
            usuraSchermoSelected: -1,
            usuraTasti: -1,
            isLoading: false,
            login: false,
            prezzoVendita: 0,
            sizeSelected: "",
            modelSelected: "",
            colorSelected: "",
            modelli: [],
            arrAnswer: [],
            colori: [
                {name: "Aluminium"},
                {name: "Rose Gold"},
                {name: "Gold"},
                {name: "Silver"},
                {name: "Space Grey"},
                {name: "Midnight Green"},
                {name: "Red"},
                {name: "Coral"},
                {name: "Yellow"},
                {name: "Light Green"},
                {name: "Light Yellow"},
                {name: "Light Purple"},
                {name: "Blue"},
                {name: "White"},
                {name: "Black"},
            ],
            dataReady: false,
            storage: [
                {name: "16 GB"},
                {name: "32 GB"},
                {name: "64 GB"},
                {name: "128 GB"},
                {name: "256 GB"},
                {name: "512 GB"}
            ]
        };
    }

    async componentDidMount() {
        this.getProdotti();
        if(localStorage.getItem('user')){
            this.setState({
                user: JSON.parse(localStorage.getItem('user'))
            })
        }
    }

    getProdotti() {
        this.setState({
            dataReady: false
        });

        api.get('prodotti/getAll')
            .then((response) => {
                this.setState({
                    modelli: response.data.prodotti
                })

            })
            .catch(error => {
                console.log(error);
            })
    }


    printHere = () => {
        this.saveToDb();
    }

    saveUser = (event) =>  {
        event.preventDefault();
        this.setState({
            isLoading: true,
            open: false
        })
        if(this.state.login) {
            let body = {
                email: event.target.email.value,
                pwd: event.target.password.value
            };
            api.post('auth/login',body)
                .then((response) => {
                    this.setState({
                        user: response.data.user
                    })
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                    if(localStorage.getItem('idRichiesta') != null && localStorage.getItem('idRichiesta') != "null") {
                        let bodyNew = {
                            "idRichiesta":localStorage.getItem('idRichiesta')
                        };
                        api.post('richieste/addUtenteRichiesta',bodyNew, {
                            headers: {
                                Authorization:response.data.user.token
                            }})
                            .then((response) => {
                                localStorage.setItem('idRichiesta', null)
                                localStorage.removeItem('arleadyRequested')
                                this.setState({
                                    isLoading: false
                                })
                                if(this.state.fromButton){
                                    this.setState({
                                        open: false
                                    })
                                } else {
                                    this.props.history.push('/profilo')
                                }
                            })
                            .catch(error => {
                                if(this.state.fromButton){
                                    this.setState({
                                        open: false
                                    })
                                } else {
                                    this.props.history.push('/profilo')
                                }
                                console.log(error);
                            })
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        if(this.state.fromButton){
                            this.setState({
                                open: false
                            })
                        } else {
                            this.props.history.push('/profilo')
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        } else {
            let body = {
                nome: event.target.nome.value,
                cognome: event.target.cognome.value,
                email: event.target.email.value,
                cellulare: event.target.cellulare.value,
                indirizzo: event.target.via.value,
                citta: event.target.citta.value,
                pwd: event.target.password.value,
                numero: event.target.numero.value,
                regione: event.target.regione.value,
                provincia: event.target.provincia.value,
                cap: event.target.cap.value
            };
            api.post('auth/registrati',body)
                .then((response) => {
                    this.setState({
                        user: response.data.user
                    })
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                    if(localStorage.getItem('idRichiesta') != null && localStorage.getItem('idRichiesta') != "null") {
                        let bodyNew = {
                            "idRichiesta":localStorage.getItem('idRichiesta')
                        };
                        api.post('richieste/addUtenteRichiesta',bodyNew, {
                            headers: {
                                Authorization:response.data.user.token
                            }})
                            .then((response) => {
                                localStorage.setItem('idRichiesta', null)
                                localStorage.removeItem('arleadyRequested')
                                this.setState({
                                    isLoading: false
                                })
                                this.props.history.push('/profilo')
                            })
                            .catch(error => {
                                this.props.history.push('/profilo')
                                console.log(error);
                            })
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.props.history.push('/profilo')
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }

    saveToDb() {
        let body = {
            "prezzo" : this.state.prezzoVendita,
            "idProdotto": this.state.modelSelected.idProdotto,
            "risposte": this.state.arrAnswer
        }
        api.post('richieste/add',body)
            .then((response) => {
                localStorage.setItem('arleadyRequested', "1")
                localStorage.setItem('idRichiesta', response.data.idRichiesta)
                if(localStorage.getItem('user')){
                    let bodyNew = {
                        "idRichiesta": response.data.idRichiesta
                    };
                    api.post('richieste/addUtenteRichiesta',bodyNew, {
                        headers: {
                            Authorization: JSON.parse(localStorage.getItem('user')).token
                        }})
                        .then((response) => {
                            localStorage.setItem('idRichiesta', null)
                            localStorage.removeItem('arleadyRequested')
                            this.setState({
                                isLoading: false
                            })
                            this.props.history.push('/profilo')
                        })
                        .catch(error => {
                            this.props.history.push('/profilo')
                            console.log(error);
                        })
                } else{
                    this.setState({
                        open: true,
                    });
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    saveModelSelected = (event) =>  {
        var result = this.state.modelli.find(obj => {
            return obj.idProdotto == event.target.value
        })
        this.setState({
            modelSelected: result,
            arrAnswer: []
        })
        this.getDomandeByProdotto(result);
    }

    getDomandeByProdotto(obj) {
        let body = {
            "tipoDomanda" : obj.tipoDomande
        }
        api.post('domande/getDomandeByProdotto',body)
            .then((response) => {
                console.log(response.data.domande);
                this.setState({
                    domande: response.data.domande
                })

            })
            .catch(error => {
                console.log(error);
            })
    }

    saveColorSelected = (event) =>  {
        this.setState({
            colorSelected: event.target.value
        })
    }

    saveSizeSelected = (event) =>  {
        this.setState({
            sizeSelected: event.target.value
        })
    }
    handleClose() {
        this.setState({
            open: false,
        });
    }

    saveAnswer = (event) => {
        let toAdd = true;
        let tmpArr = this.state.arrAnswer;
        let selected = JSON.parse(event.target.value);
        for(let i = 0; i < tmpArr.length; i++){
            if(selected.idDomanda == tmpArr[i].idDomanda) {
                tmpArr[i] = selected;
                toAdd = false;
            }
        }
        if(toAdd) {
            tmpArr.push(selected);
        }
        this.setState({
            arrAnswer: tmpArr
        })
    }

    saveUsuraSelected = (event) => {
        this.setState({
            usuraSelected: event.target.value
        })
    }
    saveTastiSelected = (event) => {
        this.setState({
            usuraTasti: event.target.value
        })
    }

    saveUsuraSchermoSelected = (event) => {
        this.setState({
            usuraSchermoSelected: event.target.value
        })
    }

    checkIfFirstCouldProceed() {
        return this.state.modelSelected && this.state.colorSelected && this.state.sizeSelected &&
            this.state.modelSelected != ""  && this.state.colorSelected != "" && this.state.sizeSelected != "";
    }

    handlePrezzoChange = (event) => {
        this.setState({ prezzoVendita: event.target.value });
    };

    accedi() {
        this.setState({
            login: true,
            fromButton: true,
            open: true
        })
    }

    logout() {
        this.setState({
            user: null
        })
        localStorage.removeItem('user');
        this.props.history.push('/');
    }

    render() {
        return (
            <div className="blank-wrapper">
                <img style={{padding: 20}} width={140} src="https://cdn.shopify.com/s/files/1/0509/2546/9866/files/logo_400x@2x.png?v=1607962088" alt=""/>
                <span onClick={() => this.accedi()} className={this.state.user ? "notDisplay" : "myAccess"}>Accedi </span>
                <span onClick={() => this.logout()} style={{color: "black"}}  className={this.state.user ? "myAccess" : "notDisplay"}>  Esci <ExitIcon/> </span>
                <span style={{cursor: "pointer"}} onClick={() => this.props.history.push('/profilo')} className={this.state.user ? "myAccess" : "notDisplay"}>Torna al profilo</span>
                <HorizontalLinearStepper
                    firstCheck={this.checkIfFirstCouldProceed.bind(this)}
                    modello={this.state.modelSelected}
                    colore={this.state.colorSelected}
                    taglia={this.state.sizeSelected}
                    colori={this.state.colori}
                    modelli={this.state.modelli}
                    storage={this.state.storage}
                    callback={this.printHere.bind(this)}
                    valueUsura={this.state.usuraSelected}
                    valueUsuraSchermo={this.state.usuraSchermoSelected}
                    saveTasti={this.saveTastiSelected.bind(this)}
                    saveModelSelected={this.saveModelSelected.bind(this)}
                    saveColorSelected={this.saveColorSelected.bind(this)}
                    saveSizeSelected={this.saveSizeSelected.bind(this)}
                    saveUsura={this.saveUsuraSelected.bind(this)}
                    saveUsuraSchermo={this.saveUsuraSchermoSelected.bind(this)}
                    valueTasti={this.state.usuraTasti}
                    prezzo={this.state.prezzoVendita}
                    handlePrezzoChange={this.handlePrezzoChange.bind(this)}
                    domande={this.state.domande}
                    arrAnswer={this.state.arrAnswer}
                    saveAnswer={this.saveAnswer.bind(this)}
                >

                </HorizontalLinearStepper>
                {
                    this.state.isLoading ? (
                            <div className="overlay">
                                <div className="overlay__inner">
                                    <div className="overlay__content"><span className="spinner"></span></div>
                                </div>
                            </div>
                    ) : (
                        <span></span>
                    )

                }

                <br/>
                <Dialog open={this.state.open} onClose={() => this.handleClose()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        <img width={100} src="https://cdn.shopify.com/s/files/1/0509/2546/9866/files/logo_400x@2x.png?v=1607962088" alt=""/><br/>
                        <br/><div onClick={() => this.setState({login: !this.state.login})} className={"myLoginText"}>{this.state.login ? "Non sei registrato? Registrati ora" :"Sei già registrato? Effettua il login"} ></div>
                    </DialogTitle>
                    <form onSubmit={this.saveUser.bind(this)}>
                        <DialogContent>
                            {
                                this.state.login ?
                                    (
                                        <span>
                                            <h5>Effettua il login</h5>
                                            <div className="myCustomModalPersonal">
                                            <FormControl className={"myFormWidth"}>
                                                    <label htmlFor="email">Email</label>
                                                    <input className={"form-control"} type="email" name="email"/>
                                                </FormControl>
                                            <FormControl className={"myFormWidth"} required>
                                                <label htmlFor="password">Password</label>
                                                <input className={"form-control"} type="password" name="password" required/>
                                            </FormControl>
                                        </div>
                                        </span>
                                    ):
                                    (<span>
                                        <h5>Informazioni personali</h5>
                                        <div className="myCustomModalPersonal">
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="nome">Nome</label>
                                                <input className={"form-control"} type="text" name="nome" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="cognome">Cognome</label>
                                                <input className={"form-control"} type="text" name="cognome" required/>
                                            </FormControl>
                                            <div style={{margin: 0}} className="row">
                                                <FormControl className={"halfWidth"} required>
                                                    <label htmlFor="email">Email</label>
                                                    <input className={"form-control"} type="email" name="email" required/>
                                                </FormControl>
                                                <FormControl className={"halfWidth"} required>
                                                    <label htmlFor="cellulare">Cellulare</label>
                                                    <input className={"form-control"} type="text" name="cellulare" required/>
                                                </FormControl>
                                            </div>
                                            <FormControl className={"myFormWidth"} required>
                                                <label htmlFor="password">Password</label>
                                                <input className={"form-control"} type="password" name="password" required/>
                                            </FormControl>
                                        </div>
                                        <br/>
                                        <h5>Indirizzo ritiro</h5>
                                        <div className="myCustomModalAddress">
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="via">Via/Viale</label>
                                                <input className={"form-control"} type="text" name="via" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="numero">Numero civico</label>
                                                <input className={"form-control"} type="text" name="numero" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="citta">Città</label>
                                                <input className={"form-control"} type="text" name="citta" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="regione">Regione</label>
                                                <input className={"form-control"} type="text" name="regione" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="cap">CAP</label>
                                                <input className={"form-control"} type="text" name="cap" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="provincia">Provincia</label>
                                                <input className={"form-control"} type="text" name="provincia" required/>
                                            </FormControl>
                                        </div>
                                    </span>)
                            }
                        </DialogContent>
                        <DialogActions style={{padding: 23}}>
                            {/*<button onClick={() => this.handleClose()}
                                    className="myButtonDisabled"> Annulla </button>*/}
                            <button style={{width: "100%"}} type="submit"
                                    color="primary"
                                    className={"myButton"}>
                                {this.state.login ? "Accedi" : "Registrati"}
                            </button>
                        </DialogActions>
                    </form>

                </Dialog>
            </div>
        );
    }
}
