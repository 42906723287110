import React, {Component} from "react";
import HorizontalLinearStepper from "../components/stepper";
import FormControl from "@material-ui/core/FormControl";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ExitIcon from "@material-ui/icons/ExitToApp";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {toast, ToastContainer} from "react-toastify";
import {Link, Redirect} from "react-router-dom";
import api from "../api/index";
import {CircularProgress, Input, OutlinedInput, Tooltip} from "@material-ui/core";
import {brown} from "@material-ui/core/colors";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "react-toastify/dist/ReactToastify.css";
import InputAdornment from "@material-ui/core/InputAdornment";

export default class Profilo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            open: false,
            login: true,
            isNotLogged: false,
            user: null,
            expanded: ""
        };
    }

    saveUser = (event) =>  {
        event.preventDefault();
        this.setState({
            openLogin: false
        })
        if(this.state.login) {
            let body = {
                email: event.target.email.value,
                pwd: event.target.password.value
            };
            api.post('auth/login',body)
                .then((response) => {
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                    this.setState({
                        user: response.data.user,
                        isNotLogged: false
                    },() => {
                        this.getVendite();
                    });
                })
                .catch(error => {
                    console.log(error);
                })
        } else {
            let body = {
                nome: event.target.nome.value,
                cognome: event.target.cognome.value,
                email: event.target.email.value,
                cellulare: event.target.cellulare.value,
                indirizzo: event.target.via.value,
                citta: event.target.citta.value,
                pwd: event.target.password.value,
                numero: event.target.numero.value,
                regione: event.target.regione.value,
                provincia: event.target.provincia.value,
                cap: event.target.cap.value
            };
            api.post('auth/registrati',body)
                .then((response) => {
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                    this.setState({
                        user: response.data.user,
                        isNotLogged: false
                    },() => {
                        this.getVendite();
                    });
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }

    async componentDidMount() {
        this.setState({
            isLoading: false,
            user: JSON.parse(localStorage.getItem('user')),
            openIban: localStorage.getItem('user') == null ? false : JSON.parse(localStorage.getItem('user')).iban == null ? true : false,
            isNotLogged: localStorage.getItem('user') == null,
            openLogin: localStorage.getItem('user') == null,
        },() => {
            if(!this.state.isNotLogged) {
                this.getVendite();
            }
        });
    }

    handleChangePanel = (panel) => (event, isExpanded) => {
        this.setState({
            expanded: isExpanded ? panel : false
        });
    };

    getVendite() {
        api.get('user/getInfoUser', {
            headers: {
                Authorization: this.state.user.token
            }})
            .then((response) => {
                if(response.data.richieste.length > 0) {
                    this.setState({
                        expanded: 'panel0'
                    })

                }
                this.setState({
                    richieste: response.data.richieste,
                    isLoading: false
                })
            })
            .catch(error => {
                console.log(error);
            })
    }

    handleClose() {
        this.setState({
            open: false,
        });
    }
    handleCloseLogin() {
        this.setState({
            openLogin: false,
        });
    }


    handleCloseIban() {
        this.setState({
            openIban: false,
        });
    }
    handleClosePrice() {
        this.setState({
            openPrice: false,
        });
        setTimeout(() => {
            this.setState({toModify: null});
        }, 500);
    }
    handleOpenPrice(data) {
        this.setState({
            toModify: data,
            openPrice: true,
        });
    }

    handleOpen() {
        this.setState({
            open: true
        })
    }
    handleOpenIban() {
        this.setState({
            openIban: true
        })
    }
    updateUserIban = (event) => {
        event.preventDefault();
        let tmpUser = this.state.user;
        tmpUser["iban"] = event.target.iban.value;
        api.post('user/editUser',tmpUser, {
            headers: {
                Authorization: this.state.user.token
            }})
            .then((response) => {
                if(response.status == 200) {
                    this.updatedToast();
                    localStorage.setItem('user', JSON.stringify(response.data.utente));
                    this.setState({
                        user: response.data.utente
                    })
                }
                this.setState({
                    openIban: false
                })

            })
            .catch(error => {
                console.log(error);
            })
    }

    updatePrice = (event) => {
        event.preventDefault();
        let body = {
            "idRichiesta" : this.state.toModify.idRichiesta,
            "prezzo": event.target.prezzo.value
        }
        api.post('user/editRichiesta',body, {
            headers: {
                Authorization: this.state.user.token
            }})
            .then((response) => {
                if(response.status == 200) {
                    api.get('user/getInfoUser', {
                        headers: {
                            Authorization: this.state.user.token
                        }})
                        .then((response) => {
                            this.setState({
                                richieste: response.data.richieste
                            })
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
                this.handleClosePrice();

            })
            .catch(error => {
                console.log(error);
            })
    }
    updateUser = (event) => {
        event.preventDefault();
        let body = {
            nome: event.target.nome.value,
            cognome: event.target.cognome.value,
            email: event.target.email.value,
            cellulare: event.target.cellulare.value,
            indirizzo: event.target.via.value,
            citta: event.target.citta.value,
            iban: event.target.iban.value,
            numero: event.target.numero.value,
            regione: event.target.regione.value,
            provincia: event.target.provincia.value,
            cap: event.target.cap.value
        };
        api.post('user/editUser',body, {
            headers: {
                Authorization: this.state.user.token
            }})
            .then((response) => {
                if(response.status == 200) {
                    this.updatedToast();
                    localStorage.setItem('user', JSON.stringify(response.data.utente));
                    this.setState({
                        user: response.data.utente
                    })
                }
                this.setState({
                    open: false
                })

            })
            .catch(error => {
                console.log(error);
            })
    }

    logout() {
        localStorage.removeItem('user');
        this.props.history.push('/');
    }

    render() {
        return (
            <div className="blank-wrapper">
                <ToastContainer autoClose={3000}/>
                <span>
                                    <img style={{padding: 20}} width={140} src="https://cdn.shopify.com/s/files/1/0509/2546/9866/files/logo_400x@2x.png?v=1607962088" alt=""/>
                                    <span onClick={() => this.logout()} style={{color: "black"}} className={"myAccess"}>   Esci <ExitIcon/> </span>
                                    <span style={{cursor: "auto"}} className={"myAccess"}>{JSON.parse(localStorage.getItem('user')) ? "Ciao, "+JSON.parse(localStorage.getItem('user')).nome: "Accedi"}</span>
                </span>



                {
                    this.state.isLoading ? (
                        <div className="overlay">
                            <div className="overlay__inner">
                                <div className="overlay__content"><span className="spinner"></span></div>
                            </div>
                        </div>
                    ) : this.state.isNotLogged ? (
                            <div>
                                <div className="row">
                                    <div className="col-sm">

                                    </div>
                                    <div style={{padding: "40px"}} className="col-sm-10">
                                        <h1>Benvenuto Mario</h1>
                                        <div className="row">
                                            <div className="col-sm">
                                                <br/>
                                                <div className="row myContainer">
                                                    <h4>Lista dispositivi</h4>
                                                    <span style={{width: "20px"}}></span>
                                                    <button
                                                        color="primary"
                                                        className={"myButtonOutlined"}
                                                        onClick={() => this.props.history.push('./')}
                                                    >
                                                        Vendi Nuovo
                                                    </button>
                                                </div>
                                                <br/>
                                                <ExpansionPanel className={"myExpansionPanel"}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon/>
                                                        }
                                                        aria-controls="panel1c-content"
                                                        id="panel1c-header"
                                                    >
                                                        <div style={{width: '100px'}}>
                                                            <p>Iphone X</p>
                                                        </div>
                                                        <div style={{float: "right", width: "100%"}}>
                                                            <span style={{backgroundColor: "orange"}} className={"myBadge"}>Da Validare</span>
                                                        </div>

                                                    </ExpansionPanelSummary>

                                                </ExpansionPanel>
                                                <br/>

                                            </div>
                                            <div className="col-sm">
                                                <br/>
                                                <div className="row myContainer">
                                                    <h4>Le tue informazioni</h4>
                                                    <span style={{width: "20px"}}></span>
                                                    <button
                                                        color="primary"
                                                        className={"myButtonOutlined"}
                                                        onClick={() => this.setState({open:true})}
                                                    >
                                                        Modifica
                                                    </button>
                                                </div>
                                                <br/>
                                                <table>
                                                    <tr>
                                                        <th>Nome</th>
                                                        <th>Cognome</th>
                                                        <th>Email</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Mario</td>
                                                        <td>Rossi</td>
                                                        <td>mrossi@validato.it</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Cellulare</th>
                                                        <th>Indirizzo</th>
                                                        <th>Iban</th>
                                                    </tr>
                                                    <tr>
                                                        <td>3334323231</td>
                                                        <td>Via Roma 45, Rima</td>
                                                        <td></td>
                                                    </tr>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm">

                                    </div>
                                </div>
                                <div className="bg-image"></div>
                            </div>

                        ) : (
                        <div>
                            <div onClick={() => this.handleOpenIban()} className={this.state.user.iban == null || this.state.user.iban == "" ? "myDanger" : "notDisplay"}>Inserisci l'iban per ricevere il compenso della tua vendita</div>

                            <br/>
                            <div className="row">
                                <div className="col-sm">

                                </div>
                                <div style={{padding: "40px"}} className="col-sm-10">
                                    <h1>Benvenuto {this.state.user.nome}</h1>
                                    <div className="row">
                                        <div className="col-sm">
                                            <br/>
                                            <div className="row myContainer">
                                                <h4>Lista dispositivi</h4>
                                                <span style={{width: "20px"}}></span>
                                                <button
                                                    color="primary"
                                                    className={"myButtonOutlined"}
                                                    onClick={() => this.props.history.push('./')}
                                                >
                                                    Vendi Nuovo
                                                </button>
                                            </div>
                                            {this.state.richieste && this.state.richieste.map((richiesta, index) => (
                                                <div key={index}>
                                                    <br/>
                                                    <ExpansionPanel className={"myExpansionPanel"} key={index} expanded={this.state.expanded == 'panel'+index} onChange={this.handleChangePanel('panel'+index)}>
                                                        <ExpansionPanelSummary
                                                            expandIcon={
                                                                <ExpandMoreIcon/>
                                                            }
                                                            aria-controls="panel1c-content"
                                                            id="panel1c-header"
                                                        >
                                                            <div style={{width: '100px'}}>
                                                                <p>{richiesta.nomeProdotto}</p>
                                                            </div>
                                                            <div style={{float: "right", width: "100%"}}>
                                                                <span style={{backgroundColor: richiesta.color}} className={"myBadge"}>{richiesta.nomeStato}</span>
                                                            </div>

                                                        </ExpansionPanelSummary>
                                                        <ExpansionPanelDetails>
                                                            <div>
                                                                <p style={{fontSize: "12px", color: "grey", margin: 0}}>{"Codice: "+richiesta.codiceRitiro}</p>
                                                                <table>
                                                                    {richiesta.risposte && richiesta.risposte.length > 0 && richiesta.risposte.map((nodo, index) => (
                                                                        <span>
                                                                         <tr>
                                                                             <th>{nodo.descrizioneRisposta}</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{nodo.testoRisposta}</td>
                                                                        </tr>
                                                                    </span>

                                                                    ))
                                                                    }
                                                                    <span>
                                                                        <tr>
                                                                            <th>Accessori</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className={richiesta.accessori == null ? "myRedText" : ""}>{richiesta.accessori == null ? "Da validare" : richiesta.accessori}</td>
                                                                        </tr>
                                                                    </span>
                                                                    <span>
                                                                        <tr>
                                                                            <th>Fotocamera</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className={richiesta.fotocamera == null ? "myRedText" : ""}>{richiesta.fotocamera == null ? "Da validare" : richiesta.fotocamera}</td>
                                                                        </tr>
                                                                    </span>
                                                                    <span>
                                                                        <tr>
                                                                            <th>Microfono</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className={richiesta.microfono == null ? "myRedText" : ""}>{richiesta.microfono == null ? "Da validare" : richiesta.microfono}</td>
                                                                        </tr>
                                                                    </span>
                                                                    <span>
                                                                        <tr>
                                                                            <th>Touchscreen</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className={richiesta.touchScreen == null ? "myRedText" : ""}>{richiesta.touchScreen == null ? "Da validare" : richiesta.touchScreen}</td>
                                                                        </tr>
                                                                    </span>
                                                                    <span>
                                                                        <tr>
                                                                            <th>Scatola</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className={richiesta.scatola == null ? "myRedText" : ""}>{richiesta.scatola == null ? "Da validare" : richiesta.scatola}</td>
                                                                        </tr>
                                                                    </span>
                                                                    <br/>
                                                                    <div>
                                                                        <span className={"mySpacerButton"}>
                                                                             <button
                                                                                 color="primary"
                                                                                 className={"myButtonSecondaryOutlined"}
                                                                                 onClick={() => this.handleOpenPrice(richiesta)}
                                                                             >
                                                                        Modifica
                                                                    </button>
                                                                        </span>

                                                                        <span style={{float: "right"}}>
                                                                        <tr className={"myPriceTh"}>
                                                                            <th>Prezzo</th>
                                                                        </tr>
                                                                        <tr className={"myPriceTh"}>
                                                                            <td>{"€ "+richiesta.prezzo}</td>
                                                                        </tr>
                                                                    </span>
                                                                    </div>

                                                                </table>

                                                            </div>
                                                        </ExpansionPanelDetails>

                                                    </ExpansionPanel>
                                                    <br/>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-sm">
                                            <br/>
                                            <div className="row myContainer">
                                                <h4>Le tue informazioni</h4>
                                                <span style={{width: "20px"}}></span>
                                                <button
                                                    color="primary"
                                                    className={"myButtonOutlined"}
                                                    onClick={() => this.setState({open:true})}
                                                >
                                                    Modifica
                                                </button>
                                            </div>
                                            <br/>
                                            <table>
                                                <tr>
                                                    <th>Nome</th>
                                                    <th>Cognome</th>
                                                    <th>Email</th>
                                                </tr>
                                                <tr>
                                                    <td>{this.state.user.nome}</td>
                                                    <td>{this.state.user.cognome}</td>
                                                    <td>{this.state.user.email}</td>
                                                </tr>
                                                <tr>
                                                    <th>Cellulare</th>
                                                    <th>Indirizzo</th>
                                                    <th>Iban</th>
                                                </tr>
                                                <tr>
                                                    <td>{this.state.user.cellulare}</td>
                                                    <td>{this.state.user.indirizzo}</td>
                                                    <td>{this.state.user.iban == null ? (<span className={"myRedText"}>Da Inserire</span>) : this.state.user.iban}</td>
                                                </tr>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm">

                                </div>
                            </div>


                        </div>

                    )

                }

                <br/>
                <Dialog disableBackdropClick="true" open={this.state.openLogin} onClose={() => this.handleCloseLogin()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        <img width={100} src="https://cdn.shopify.com/s/files/1/0509/2546/9866/files/logo_400x@2x.png?v=1607962088" alt=""/><br/>
                        <br/><div onClick={() => this.setState({login: !this.state.login})} className={"myLoginText"}>{this.state.login ? "Non sei registrato? Registrati ora" :"Sei già registrato? Effettua il login"} ></div>
                    </DialogTitle>
                    <form onSubmit={this.saveUser.bind(this)}>
                        <DialogContent>
                            {
                                this.state.login ?
                                    (
                                        <span>
                                            <h5>Effettua il login</h5>
                                            <div className="myCustomModalPersonal">
                                            <FormControl className={"myFormWidth"}>
                                                    <label htmlFor="email">Email</label>
                                                    <input className={"form-control"} type="email" name="email"/>
                                                </FormControl>
                                            <FormControl className={"myFormWidth"} required>
                                                <label htmlFor="password">Password</label>
                                                <input className={"form-control"} type="password" name="password" required/>
                                            </FormControl>
                                        </div>
                                        </span>
                                    ):
                                    (<span>
                                        <h5>Informazioni personali</h5>
                                        <div className="myCustomModalPersonal">
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="nome">Nome</label>
                                                <input className={"form-control"} type="text" name="nome" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="cognome">Cognome</label>
                                                <input className={"form-control"} type="text" name="cognome" required/>
                                            </FormControl>
                                            <div style={{margin: 0}} className="row">
                                                <FormControl className={"halfWidth"} required>
                                                    <label htmlFor="email">Email</label>
                                                    <input className={"form-control"} type="email" name="email" required/>
                                                </FormControl>
                                                <FormControl className={"halfWidth"} required>
                                                    <label htmlFor="cellulare">Cellulare</label>
                                                    <input className={"form-control"} type="text" name="cellulare" required/>
                                                </FormControl>
                                            </div>
                                            <FormControl className={"myFormWidth"} required>
                                                <label htmlFor="password">Password</label>
                                                <input className={"form-control"} type="password" name="password" required/>
                                            </FormControl>
                                        </div>
                                        <br/>
                                        <h5>Indirizzo ritiro</h5>
                                        <div className="myCustomModalAddress">
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="via">Via/Viale</label>
                                                <input className={"form-control"} type="text" name="via" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="numero">Numero civico</label>
                                                <input className={"form-control"} type="text" name="numero" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="citta">Città</label>
                                                <input className={"form-control"} type="text" name="citta" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="regione">Regione</label>
                                                <input className={"form-control"} type="text" name="regione" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="cap">CAP</label>
                                                <input className={"form-control"} type="text" name="cap" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="provincia">Provincia</label>
                                                <input className={"form-control"} type="text" name="provincia" required/>
                                            </FormControl>
                                        </div>
                                    </span>)
                            }
                        </DialogContent>
                        <DialogActions style={{padding: 23}}>
                            {/*<button onClick={() => this.handleClose()}
                                    className="myButtonDisabled"> Annulla </button>*/}
                            <button style={{width: "100%"}} type="submit"
                                    color="primary"
                                    className={"myButton"}>
                                {this.state.login ? "Accedi" : "Registrati"}
                            </button>
                        </DialogActions>
                    </form>

                </Dialog>
                <Dialog open={this.state.open} onClose={() => this.handleClose()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        Modifica Informazioni
                    </DialogTitle>
                    <form onSubmit={this.updateUser.bind(this)}>
                        <DialogContent>

                                        <h5>Informazioni personali</h5>
                                        <div className="myCustomModalPersonal">
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="nome">Nome</label>
                                                <input className={"form-control"} type="text" name="nome" defaultValue={this.state.user && this.state.user.nome} required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="cognome">Cognome</label>
                                                <input className={"form-control"} type="text" name="cognome" defaultValue={this.state.user && this.state.user.cognome} required/>
                                            </FormControl>
                                            <div style={{margin: 0}} className="row">
                                                <FormControl className={"halfWidth"} required>
                                                    <label htmlFor="email">Email</label>
                                                    <input className={"form-control"} type="email" name="email"  defaultValue={this.state.user && this.state.user.email} required/>
                                                </FormControl>
                                                <FormControl className={"halfWidth"} required>
                                                    <label htmlFor="cellulare">Cellulare</label>
                                                    <input className={"form-control"} type="text" name="cellulare" defaultValue={this.state.user && this.state.user.cellulare} required/>
                                                </FormControl>
                                            </div>
                                            <FormControl className={"myFormWidth"}>
                                                <label htmlFor="iban">Iban</label>
                                                <input className={"form-control"} type="text" name="iban" defaultValue={this.state.user && this.state.user.iban}/>
                                            </FormControl>
                                        </div>
                                        <br/>
                                        <h5>Indirizzo ritiro</h5>
                                        <div className="myCustomModalAddress">
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="via">Via/Viale</label>
                                                <input className={"form-control"} type="text" name="via" defaultValue={this.state.user && this.state.user.indirizzo} required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="numero">Numero civico</label>
                                                <input className={"form-control"} type="text" name="numero" defaultValue={this.state.user && this.state.user.numero} required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="citta">Città</label>
                                                <input className={"form-control"} type="text" name="citta" defaultValue={this.state.user && this.state.user.citta} required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="regione">Regione</label>
                                                <input className={"form-control"} type="text" name="regione" defaultValue={this.state.user && this.state.user.regione} required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="cap">CAP</label>
                                                <input className={"form-control"} type="text" name="cap" defaultValue={this.state.user && this.state.user.cap} required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="provincia">Provincia</label>
                                                <input className={"form-control"} type="text" name="provincia" defaultValue={this.state.user && this.state.user.provincia} required/>
                                            </FormControl>
                                        </div>
                        </DialogContent>
                        <DialogActions style={{padding: 23}}>
                            <button style={{width: "100%"}} type="submit"
                                    color="primary"
                                    className={"myButton"}>
                                Modifica
                            </button>
                        </DialogActions>
                    </form>

                </Dialog>
                <Dialog open={this.state.openIban} onClose={() => this.handleCloseIban()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        Attenzione
                    </DialogTitle>
                    <form onSubmit={this.updateUserIban.bind(this)}>
                        <DialogContent>
                            <p>Inserisci l'iban del tuo conto o della tua carta in modo da ricevere il pagamento quando il tuo telefono verrà acquistato.</p>
                            <div className="myCustomModalPersonal">
                                <FormControl className={"myFormWidth"}>
                                    <label htmlFor="iban">Iban</label>
                                    <input className={"form-control"} type="text" name="iban" />
                                </FormControl>
                            </div>
                        </DialogContent>
                        <DialogActions style={{padding: 23}}>
                            <button style={{width: "100%"}} type="submit"
                                    color="primary"
                                    className={"myButton"}>
                                Aggiungi Iban
                            </button>
                        </DialogActions>
                    </form>

                </Dialog>
                <Dialog open={this.state.openPrice} onClose={() => this.handleClosePrice()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        Modifica Prezzo
                    </DialogTitle>
                    <form onSubmit={this.updatePrice.bind(this)}>
                        <DialogContent>
                            <p>Modifica il prezzo di vendita del tuo dispositivo</p>
                            <div className="myCustomModalPersonal">
                                <FormControl className={"myFormWidth"} required>
                                    <label htmlFor="iban">Prezzo</label>
                                    <OutlinedInput
                                            style={{backgroundColor: "white"}}
                                           startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                           type="text" name="prezzo"
                                           defaultValue={this.state.toModify && this.state.toModify.prezzo} required/>
                                </FormControl>
                            </div>
                        </DialogContent>
                        <DialogActions style={{padding: 23}}>
                            <button style={{width: "100%"}} type="submit"
                                    color="primary"
                                    className={"myButton"}>
                                Modifica
                            </button>
                        </DialogActions>
                    </form>

                </Dialog>
            </div>
        );
    }

    updatedToast = () => toast.success("✔️ Utente Modificato con Successo !", {
        position: toast.POSITION.TOP_RIGHT,
    });
}
