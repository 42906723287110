import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from "@material-ui/core/InputAdornment";
import {Input, InputLabel, MenuItem, OutlinedInput, Select, TextField} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/PhoneIphone";
import GroupAddIcon from "@material-ui/icons/Build";
import VideoLabelIcon from "@material-ui/icons/AttachMoney";
import InfoIcon from "@material-ui/icons/Info";
import clsx from "clsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Popover from '@material-ui/core/Popover';
import {Link} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    smallFont: {
        fontWeight: 200
    },
    label: {
        fontWeight: 200
    },
    radio: {
        '&$checked': {
            color: '#45A626'
        }
    },
    checked: {}
}));

function getSteps() {
    return ['Modello', 'Usura', 'Prezzo'];
}


function getStepContent(anchorEl, handleClick, handleClose, open, id, classes, step, modelli, modello, colori, colore, storage, taglia, valueUsura, valueUsuraSchermo, valueTasti, prezzo, saveModelSelected, saveColorSelected, saveSizeSelected, saveUsura, saveUsuraSchermo, saveTasti, handlePrezzoChange, domande, saveAnswer, arrAnswer) {

    switch (step) {
        case 0:
            return <div>
                <h2>Modello</h2>
                <br/>

                <Typography style={{textAlign: "left"}} variant="p">Inserisci il modello del tuo telefono</Typography>
                <br/>
                {/*<Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={2}>
                            {modelli?.map((value) => (
                                <Grid key={value.name} item>
                                    <button className="mySelectButton"> {value.name}</button>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>*/}
                <Select
                    native
                    id="modello"
                    variant="outlined"
                    style={{width: "100%", fontWeight: 350}}
                    value={modello.idProdotto != null ? modello.idProdotto : modello}
                    classes={{input: classes.smallFont}}
                    onChange={saveModelSelected}
                >
                    <option value="" disabled>
                        --Seleziona--
                    </option>
                    {modelli && modelli.map(com => (
                        <option key={com.nomeProdotto} value={com.idProdotto} name={com.nomeProdotto}>
                            {com.nomeProdotto}
                        </option>
                    ))}
                </Select>
                {domande == null || domande.length == 0 ? (
                    <span>
                        <br/>
                        <br/>
                        <Typography style={{textAlign: "left"}}
                                    variant="p">Inserisci il colore del tuo telefono</Typography>
                        <br/>
                        <Select
                            native
                            id="colore"
                            variant="outlined"
                            style={{width: "100%", fontWeight: 350}}
                            value={""}
                            classes={{input: classes.smallFont}}
                        >
                            <option value="" disabled>
                                --Seleziona--
                            </option>

                        </Select>
                        <br/>
                        <br/>
                        <Typography style={{textAlign: "left"}}
                                    variant="p">Inserisci la capacità del tuo telefono</Typography>
                        <br/>
                        <Select
                            native
                            id="colore"
                            variant="outlined"
                            style={{width: "100%", fontWeight: 350}}
                            classes={{input: classes.smallFont}}
                            value={""}
                        >
                            <option value="" disabled>
                                --Seleziona--
                            </option>

                        </Select>
                    </span>
                ) : domande && domande.length > 0 && domande.map((nodo, index) => nodo.posizione == 1 ? (
                    <span>
                        <br/>
                        <br/>
                        <Typography style={{textAlign: "left"}} variant="p">{nodo.titoloDomanda}</Typography>
                        <span className={nodo.infoDomanda == null || nodo.infoDomanda == "" ? "notDisplay" : ""}>
                            <span className={"myHelper"} aria-describedby={id} onClick={handleClick}>
                            <InfoIcon fontSize={"small"}/>Informazioni
                        </span>
                            {/*TODO controllo se ci sono due info nella stessa pagina bug*/}
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <div style={{padding: 15}}>
                                <InfoIcon fontSize={"small"}/> Informazioni <br/>
                                <p className={"myHelperText"}>
                                    {nodo.infoDomanda}
                                </p>
                            </div>
                        </Popover>
                        </span>
                        <br/>
                        <Select
                            native
                            id="colore"
                            variant="outlined"
                            style={{width: "100%", fontWeight: 350}}
                            value={arrAnswer && arrAnswer.length > 0 ? JSON.stringify(arrAnswer?.find(obj => {
                                return obj.idDomanda == nodo.idDomanda && obj?.idRisposta != null;
                            })) : "-1"
                            }
                            classes={{input: classes.smallFont}}
                            onChange={saveAnswer}
                        >
                            <option value="-1" disabled>
                                --Seleziona--
                            </option>
                            {nodo.risposte && nodo.risposte.map(com => (
                                <option key={com.idRisposta} value={JSON.stringify(com)} name={com.testoRisposta}>
                                    {com.testoRisposta}
                                </option>
                            ))}
                        </Select>
                    </span>
                ) : <span></span>)

                }
            </div>;
        case 1:
            return <div>
                <h2>Usura</h2>
                <br/>
                {domande && domande.length > 0 && domande.map((nodo, index) => nodo.posizione == 2 ? (
                    <span>
                        <Typography style={{textAlign: "left"}} variant="p">{nodo.titoloDomanda}</Typography>
                                                <span
                                                    className={nodo.infoDomanda == null || nodo.infoDomanda == "" ? "notDisplay" : ""}>
                            <span className={"myHelper"} aria-describedby={id} onClick={handleClick}>
                            <InfoIcon fontSize={"small"}/>Informazioni
                        </span>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <div style={{padding: 15}}>
                                <InfoIcon fontSize={"small"}/> Informazioni <br/>
                                <p className={"myHelperText"}>
                                    {nodo.infoDomanda}
                                </p>
                            </div>
                        </Popover>
                        </span>
                        <br/>
                        <br/>
                        <FormControl component="fieldset">
                        <RadioGroup row aria-label="batteria" name="batteria"
                                    value={arrAnswer && arrAnswer.length > 0 ? JSON.stringify(arrAnswer?.find(obj => {
                                        return obj.idDomanda == nodo.idDomanda && obj?.idRisposta != null;
                                    })) : "-1"
                                    } onChange={(e) => saveAnswer(e)}>
                             {nodo.risposte && nodo.risposte.map(com => (
                                 <FormControlLabel classes={{label: classes.label}}
                                                   value={JSON.stringify(com)}
                                                   control={<Radio
                                                       classes={{root: classes.radio, checked: classes.checked}}/>}
                                                   label={com.testoRisposta}>
                                     {com.testoRisposta}
                                 </FormControlLabel>
                             ))}
                        </RadioGroup>
                    </FormControl>
                        <br/>
                        <br/>
                    </span>
                ) : <span></span>)
                }
            </div>;
        case 2:
            return <div>
                <div className="row">
                    <div className="col-sm myResume">
                        <br/>
                        <h5>Riepilogo</h5>
                        <table>
                         <span>
                            <tr>
                                <th>Modello</th>
                            </tr>
                            <tr>
                                <td>{modello.nomeProdotto}</td>
                            </tr>
                        </span>
                            {arrAnswer && arrAnswer.length > 0 && arrAnswer.map((nodo, index) => (
                                <span>
                                     <tr>
                                         <th>{nodo.descrizioneRisposta}</th>
                                    </tr>
                                    <tr>
                                        <td>{nodo.testoRisposta}</td>
                                    </tr>
                                </span>

                            ))
                            }

                        </table>
                        <br/>
                    </div>
                    <div className="col-sm">
                        <br/>
                        <h2>Ci sei quasi !</h2>
                        <br/>
                        <p>Aggiungi il prezzo di vendita per pubblicare il tuo annuncio su <span
                            style={{fontWeight: 500}}>Validato</span> e prenota il ritiro per la validazione gratuita
                            per il tuo dispositivo.</p>
                        <br/>
                        <InputLabel htmlFor="name-prod">Prezzo di vendita</InputLabel>
                        <OutlinedInput inputProps={{min: "0", max: "100000", step: "1", inputMode: 'numeric', pattern: '[0-9]*'}}
                                       startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                       id="name-prod"
                                       type="number"
                                       style={{width: "100%", fontWeight: 350}}
                                       value={prezzo} onChange={handlePrezzoChange}

                        />
                    </div>

                </div>
            </div>;
        default:
            return 'Unknown step';
    }
}

export default function HorizontalLinearStepper({
                                                    firstCheck,
                                                    modello,
                                                    colore,
                                                    taglia,
                                                    callback,
                                                    modelli,
                                                    colori,
                                                    storage,
                                                    valueUsura,
                                                    valueUsuraSchermo,
                                                    valueTasti,
                                                    saveModelSelected,
                                                    saveColorSelected,
                                                    saveSizeSelected,
                                                    saveUsura,
                                                    saveUsuraSchermo,
                                                    saveTasti,
                                                    prezzo,
                                                    handlePrezzoChange,
                                                    domande,
                                                    saveAnswer,
                                                    arrAnswer
                                                }) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps();

    const isStepOptional = (step) => {
        return step === -1;
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    const useColorlibStepIconStyles = makeStyles({
        root: {
            backgroundColor: '#ccc',
            zIndex: 1,
            color: '#fff',
            width: 50,
            height: 50,
            display: 'flex',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        active: {
            backgroundColor: '#45A626',
        },
        completed: {
            backgroundColor: '#45A626',
        },
    });

    function ColorlibStepIcon(props) {
        const classes = useColorlibStepIconStyles();
        const {active, completed} = props;

        const icons = {
            1: <SettingsIcon/>,
            2: <GroupAddIcon/>,
            3: <VideoLabelIcon/>,
        };

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })}
            >
                {icons[String(props.icon)]}
            </div>
        );
    }


    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            //throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    console.log(arrAnswer);
    return (
        <div className={classes.root}>
            <Stepper position="top" activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    /*if (isStepOptional(index)) {
                        labelProps.optional = <Typography variant="caption">Optional</Typography>;
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }*/
                    return (
                        <Step style={{color: "red"}} key={label} {...stepProps}>
                            <StepLabel StepIconComponent={ColorlibStepIcon} {...labelProps}>{""}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Typography className={classes.instructions}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset} className={classes.button}>
                            Reset
                        </Button>
                    </div>
                ) : (
                    <div>
                        <div className="row">
                            <div className="col-sm">
                            </div>
                            <div style={{padding: "40px"}} className="col-sm-8">
                                {getStepContent(anchorEl, handleClick, handleClose, open, id, classes, activeStep, modelli, modello, colori, colore, storage, taglia, valueUsura, valueUsuraSchermo, valueTasti, prezzo, saveModelSelected, saveColorSelected, saveSizeSelected, saveUsura, saveUsuraSchermo, saveTasti, handlePrezzoChange, domande, saveAnswer, arrAnswer)}
                                <br/>
                                <div style={{textAlign: "center"}}>
                                    <button style={{float: "left"}} disabled={activeStep === 0} onClick={handleBack}
                                            className={"myButtonDisabled"}>
                                        Indietro
                                    </button>
                                    <button
                                        disabled={(activeStep === 0 && arrAnswer?.length < domande?.filter(obj => {
                                            return obj.posizione === 1
                                        }).length || !domande) || (activeStep === 1 && arrAnswer?.length < domande?.filter(obj => {
                                            return obj.posizione === 1
                                        }).length + domande?.filter(obj => {
                                            return obj.posizione === 2
                                        }).length) || activeStep == 2 && prezzo <= 0}
                                        color="primary"
                                        onClick={activeStep === steps.length - 1 ? callback : handleNext}
                                        className={"myButton"}
                                    >
                                        {activeStep === steps.length - 1 ? 'Completa' : 'Avanti'}
                                    </button>
                                </div>

                                {/*{isStepOptional(activeStep) && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSkip}
                                        className={classes.button}
                                    >
                                        Skip
                                    </Button>
                                )}*/}
                            </div>
                            <div className="col-sm">
                            </div>
                        </div>

                    </div>
                )}
            </div>
        </div>
    );
}
