import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Seller from "./views/seller";
import 'bootstrap/dist/css/bootstrap.min.css';
import Profilo from "./views/profilo";

const Routing = () => {
    return(
        <Router>
            <Switch>
                <Route exact path="/" component={Seller} />
                <Route exact path="/profilo" component={Profilo} />
            </Switch>
        </Router>
    )
}


ReactDOM.render(
    <React.StrictMode>
        <Routing />
    </React.StrictMode>,
    document.getElementById('root')
);
